var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forgotPassword"},[_c('div',{staticClass:"top"},[_c('van-nav-bar',{attrs:{"title":"忘记密码","left-text":"返回","left-arrow":""},on:{"click-left":function($event){return _vm.$router.back()}}})],1),_c('div',{staticClass:"telephone"},[_c('van-form',{ref:"myFrom",attrs:{"validate-first":""},on:{"submit":_vm.onSubmit}},[_c('van-cell-group',[_c('van-field',{attrs:{"label":"手机号","type":"tel","name":"telephone","clearable":"","placeholder":"手机号","rules":[
            { required: true, message: '请输入手机号', trigger: 'onChange' },
            {
              pattern: /^1[3-9]\d{9}$/,
              message: '请输入正确手机号',
              trigger: 'onChange',
            },
          ]},model:{value:(_vm.telephone),callback:function ($$v) {_vm.telephone=$$v},expression:"telephone"}})],1)],1)],1),_c('div',{staticClass:"nextTick"},[_c('van-button',{attrs:{"type":"info","size":"large","loading":_vm.loading},on:{"click":_vm.nextHandler}},[_vm._v("下一步")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }