<template>
  <div class="forgotPassword">
    <div class="top">
      <van-nav-bar
        title="忘记密码"
        left-text="返回"
        left-arrow
        @click-left="$router.back()"
      />
    </div>
    <div class="telephone">
      <van-form
        validate-first
        ref="myFrom"
        @submit="onSubmit"
      >
        <van-cell-group>
          <van-field
            label="手机号"
            v-model="telephone"
            type="tel"
            name="telephone"
            clearable
            placeholder="手机号"
            :rules="[
              { required: true, message: '请输入手机号', trigger: 'onChange' },
              {
                pattern: /^1[3-9]\d{9}$/,
                message: '请输入正确手机号',
                trigger: 'onChange',
              },
            ]"
          />
        </van-cell-group>
      </van-form>
    </div>
    <div class="nextTick">
      <van-button
        type="info"
        size="large"
        @click="nextHandler"
        :loading="loading"
      >下一步</van-button>
    </div>
  </div>
</template>

<script>
import { reqGetCode } from '@/api/user'

export default {
  name: 'forgotPassword',
  data() {
    return {
      telephone: '',
      loading: false

    }
  },
  methods: {
    // 点击下一步
    nextHandler() {
      this.$refs.myFrom.submit()
    },
    // 提交手机号获取验证码
    async onSubmit(values) {
      this.loading = true
      try {
        await reqGetCode(this.telephone)
        this.loading = false
        this.$router.push({
          path: '/captcha',
          query: {
            telephone: this.telephone
          }
        })
      } catch (error) {
        this.loading = false
        if (error.response.data.status === 'E00030001') {
          this.$toast.fail({
            message: '请求过于频繁请稍后再试!',
            duration: 500
          })
          return
        }
        if (error.response.data.status === 'E00010004') {
          this.$toast.fail({
            message: '手机号码不存在!',
            duration: 500
          })
          return
        }
        if (error.response.data.status === 'E00030099') {
          this.$toast.fail({
            message: '短信发送失败，请稍后再试，如失败超过三次请联系管理员!',
            duration: 500
          })
          return
        }
        if (error.response.data.status === 'E00030098') {
          this.$toast.fail({
            message: '余额不足，请联系管理员!',
            duration: 500
          })
          return
        }
        this.$toast.fail({
          message: '获取验证码失败!',
          duration: 500
        })
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.forgotPassword {
  ::v-deep {
    .nextTick {
      text-align: center;

      .van-button--info {
        margin-top: 50px;
        width: 90%;
        height: 36px;
        border-radius: 5px;
        background-color: #1990ff;

      }
    }
  }
}
</style>
